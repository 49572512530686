/**
 * 根据key返回url中的value
 * @param {string} key
 * @param {string} [url]
 * @returns {string}
 */
function query (key, url) {
    return queryParse(url)[key];
}

/**
 * 返回 querystring 对象
 * @param url
 * @return {Object} 对象
 */
function queryParse (url) {
    let dic = {};
    url = url || location.search.slice(1);
    let array = url.split('&');
    for (let i = 0, len = array.length; i < len; i++) {
        let params = array[i].split('=');
        if (params[0]) {
            dic[decodeURIComponent(params[0])] = decodeURIComponent(params[1])
        }
    }
    return dic;
}

/**
 * 将qs的对象序列化成 querystring 字符串
 *
 * @param {object} obj
 * @return {string}
 */
function queryStringify (obj) {
    let qs = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            qs.push(
                encodeURIComponent(p) +
                '=' +
                encodeURIComponent(obj[p])
            )
        }
    }
    return qs.join('&');
}

function getOs () {
    let ua = navigator.userAgent.toLowerCase();
    let os = '';
    let version = '';
    let vInfo = '';
    if (ua.indexOf('android') > 0) {
        let reg = /android [\d._]+/gi;
        vInfo = ua.match(reg);
        os = 'Android';
        version = (vInfo + '').replace(/[^0-9|_.]/ig, '').replace(/_/ig, '.');
    } else if (ua.indexOf('like mac os x') > 0) {
        let reg = /os [\d._]+/gi;
        vInfo = ua.match(reg);
        os = 'iOS';
        version = (vInfo + '').replace(/[^0-9|_.]/ig, '').replace(/_/ig, '.');
    }
    return {
        os: os,
        version: version
    };
}

export {
    query,
    queryParse,
    queryStringify,
    getOs
}
