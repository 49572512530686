import { queryStringify, getOs } from './utils'

export class Report {
    /**
     * 数据上报
     * @param reportId
     * @param cmd
     */
    constructor ({ reportId = 'midu-user', cmd = '25201' }) {
        this.reportId = reportId;
        this.cmd = cmd;
    }
    /**
     * 上报数据中心
     * @param eventId 事件id 上报pv,uv不需要
     * @param position 事件名称 上报pv,uv不需要
     * @param data 上报数据详细 上报pv,uv不需要
     * @param cmd
     */
    dataCenter ({ eventId = '', position = '', data = {} }) {
        let args = [
            `eventId=${eventId}`,
            `os=${getOs().os}`,
            `osVersion=${getOs().version}`,
            `topic=xcx_web_log`,
        ].join('&');
        new Image().src = `https://ddd.1sapp.com/?cmd=${this.cmd}&userId=${this.reportId}&${args}&${queryStringify(position)}`;
    }
}
export default Report
