<template>
  <div id="app">
    <div class="midu-site">
      <slider ref="slider" :options="options" @slide="slide">
        <slideritem>
          <div class="item item-1">
            <div class="item-bg"></div>
            <div class="item-logo"></div>
            <div class="item-title"></div>
          </div>
        </slideritem>
        <slideritem>
          <div class="item item-2">
            <div class="item-slogan"></div>
            <div class="item-title"></div>
            <div class="item-icon"></div>
          </div>
        </slideritem>
        <slideritem>
          <div class="item item-3">
            <div class="item-bg"></div>
            <div class="item-title"></div>
            <div class="item-slogan"></div>
          </div>
        </slideritem>
        <slideritem>
          <div class="item item-4">
            <div class="item-title"></div>
            <div class="item-slogan"></div>
            <div class="item-icon1"></div>
            <div class="item-icon2"></div>
            <div class="item-icon3"></div>
            <div class="item-icon4"></div>
          </div>
        </slideritem>
        <slideritem>
          <div class="item item-5">
            <div class="item-bg">
              <div class="left-oval"></div>
              <div class="right-oval"></div>
            </div>
            <div class="item-title"></div>
            <div class="item-book"></div>
            <div class="link" :class="{'link-index': currentPage == 4}">
              <a
                class="link-btn"
                target="_blank"
                href="https://www.huanduwenxue.com/#/login?from=midureader"
              >成为作者</a>
              <div>
                <a
                  class="link-default"
                  target="_blank"
                  href="https://m.midukanshu.com/huandu/welfare.html?from=midureader"
                >查看福利</a>
              </div>
            </div>
          </div>
        </slideritem>
        <slideritem>
          <div class="item item-6">
            <div class="item-bg"></div>
            <div class="title-1">上海大犀角信息科技有限公司</div>
            <div class="contact-border">
              <div class="contact">
                <div class="title-2">
                  <i class="icon icon-1"></i>
                  <strong>地址：</strong>
                  <span>
                    上海市嘉定区真南路
                    <br />4268号2幢JT7450室
                  </span>
                </div>
                <div class="title-3">
                  <i class="icon icon-2"></i>
                  <strong>邮编：</strong>
                  <span>200000</span>
                </div>
                  <div class="title-4">
                      <i class="icon icon-3"></i>
                      <strong>电话：</strong>
                      <span>021-68782586</span>
                  </div>
              </div>
            </div>
            <div class="gov-info" @click="goGovEve">
              <span>
                <img src="./images/6/gov-info.png" alt />
              </span>
                <span style="text-align: left">
                举报电话：
                <br />0553-8820057
              </span>
            </div>

            <div class="gov-info gov-info-2" @click="goGovEve">
              <span>
                <img src="./images/6/12377.png" alt />
              </span>
            </div>
          </div>
        </slideritem>

        <div slot="loading">loading...</div>
      </slider>
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
      <div class="btn-group">
        <div
          class="download-btn-midu"
          @click="downLoadApp('midu')"
          :class="{'cur':( ((currentPage+1)%2) == 0 || currentPage == 4)}"
          v-show="currentPage!==4"
        >下载米读小说</div>
<!--        <div-->
<!--          class="download-btn-quick"-->
<!--          @click="downLoadApp('quick')"-->
<!--          :class="{'cur':( ((currentPage+1)%2) == 0 || currentPage == 4)}"-->
<!--          v-show="currentPage!==4"-->
<!--        >下载米读小说极速版</div>-->
      </div>
      <div class="icp" v-if="currentPage == 5">
        <p>沪ICP备18045778号-1</p>
        <!-- <p>客服电话：021-68782586</p> -->
      </div>
    </div>

    <div class="down-load-modal" v-if="downLoadModal">
      <div class="down-load-modal-img">
        <img src="./images/down-load-step.png" alt />
      </div>
      <div class="btn" @click="downLoadModal = false">我知道了</div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import Report from '../../assets/common/report'
import { query } from '../../assets/common/utils';

export default {
    name: 'mobile',
    components: {
        slider,
        slideritem
    },
    data () {
        return {
            options: {
                currentPage: 0,
                direction: 'vertical',
                preventRebound: true
                // thresholdDistance:500,
                // thresholdTime:100,
                // autoplay:1000,
                // loop:true,
                // loopedSlides:1,
                // slidesToScroll:1,
                // timingFunction: 'ease',
                // speed: 300,
            },
            currentPage: 0,
            breath: 1,
            timer: null,
            downLoadModal: false, // 在微博客户端，控制遮罩层提示用浏览器打开
        };
    },
    mounted () {
        this.initReport();
    },
    methods: {
        goGovEve () {
            window.location = '//www.12377.cn'
        },
        initReport () {
            this.report = new Report({
                reportId: 'midu-user',
                cmd: '25201'
            });
            this.report.dataCenter({
                eventId: '1',
                position: {
                    dtu: query('dtu') || ''
                }
            });
        },
        slide (data) {
            this.currentPage = data.currentPage
        },
        downLoadApp (type) {
            let typeObj = {
                midu: {
                    href: '//a.app.qq.com/o/simple.jsp?pkgname=com.lechuan.midunovel',
                    apk: '//a.app.qq.com/o/simple.jsp?pkgname=com.lechuan.midunovel'
                    // apk: 'https://apk.midukanshu.com/browser/apk/midu_android_midugw01.apk'
                },
                quick: {
                    href: '//a.app.qq.com/o/simple.jsp?pkgname=com.lechuan.mdwz',
                    apk: 'https://jisu-apk.midukanshu.com/download/release/miduspeed_android_jisu164627.apk'
                }
            }[type];

            this.report.dataCenter({
                eventId: '2',
                position: {
                    dtu: query('dtu') || '',
                    page: this.currentPage + 1
                }
            });
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const UA = navigator.userAgent.toLowerCase();
                const env = {
                    QQ: UA.match(/QQ/i) == 'qq', // QQ浏览器
                    weiBo: UA.match(/WeiBo/i) == 'weibo', // 新浪微博
                    weChat: UA.match(/MicroMessenger/i) == 'micromessenger', // 微信浏览器
                    iOS: /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) // iOS
                };
                if (env.iOS && env.weiBo) {
                    this.downLoadModal = true;
                } else if (env.iOS || env.weChat || env.QQ || env.weiBo) {
                    window.location.href = typeObj.href;
                } else {
                    window.location.href = typeObj.apk;
                }
            }, 300);
        }
    }
};
</script>

<style lang="less">
    @import './home.less';
    .midu-site {
        .swiper-container-vertical .slider-pagination-bullets {
            display: none;
        }
        .slider-pagination-bullets{
            width: auto;
            left: inherit;
            right: 200px;
        }
        .slider-pagination-bullet{
            width: 14px;
            height: 14px;
            background: #383838;
            opacity: 1;
        }
        .slider-pagination-bullet-active{
            background: blue;
        }
        .slider-copy {
            background: #eee;
        }
    }
.icp{
    p{
        margin: 0;
        padding: 0;
    }
}

.down-load-modal{
    background: rgba(0, 0, 0, .9);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    text-align: center;
    .down-load-modal-img{
        width: 100%;
        overflow: hidden;
        img{
            width: 80%;
            float: right;
        }
    }
    .btn{
        width:160px;
        height:49px;
        border-radius:25px;
        border:2px solid rgba(255,255,255,1);
        color: #ffffff;
        font-size: 22px;
        line-height: 49px;
        margin: 20px auto 0 auto;
    }
}

</style>
